<template>
  <div>
    <div v-for="(e, i) in multyRows" class="form-line" :key="i">
      <span
        v-if="!singleSelectMode"
        style="min-width:3rem;text-align: right; margin-right: 0.5rem;font-weight: bold;"
        >{{ i + 1 }}.</span
      >
      <div>
        <textarea
          v-if="!selectMode || multyRows[i]"
          ref="textareas"
          v-model="selectMode ? multyRows[i].label : multyRows[i]"
          class="vInput"
          width="100%"
          cols=""
          rows="1"
          :disabled="disabled || selectMode"
          :class="{ 'form-disabled': disabled || selectMode }"
          @blur="(ev) => blurInput(ev, i)"
          @keydown.tab.exact.prevent="addInput(true)"
          @keydown.ctrl.enter="addInput(true)"
          @keydown.ctrl.delete.prevent="delIndex(i, true)"
        ></textarea>
        <v-select
          v-else
          label="label"
          :disabled="disabled"
          :options="preparedSelectOptions"
          :loading="apiLoading"
          @option:selected="selectOption"
        >
          <template #option="option">
            <span class="v-select_li_custom">
              <span>{{ option["label"] }}</span>
              <i v-tooltip="option['label']" class="fas fa-info-circle"></i>
            </span>
          </template>
        </v-select>
      </div>
      <span
        v-if="(!singleSelectMode && multyRows[i]) || i > 0"
        @click="delIndex(i)"
        class="close-form"
        :style="
          autoMode && !canDeleteLast && i == lastIndex
            ? 'visibility: hidden;'
            : ''
        "
      >
        <i class="fas fa-times"></i>
      </span>
    </div>
    <template v-if="!singleSelectMode && !autoMode">
      <div class="form-line" style="margin-top: -0.5rem;">
        <span
          style="min-width:3rem;text-align: right; margin-right: 0.5rem;font-weight: bold;visibility:hidden;"
          >{{ multyRows.length }}.</span
        >
        <div>
          <div @click="addInput" class="Button1">
            <div class="icon">
              <img
                @load="imgLoaded"
                src="../assets/media/for_table/circle_ADD_new_etap.svg"
                alt=""
              />
            </div>
            <span class="Btn_content">
              Adaugă câmp
            </span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import initTextarea from "@/mixins/initTextarea.js";

export default {
  props: {
    value: {
      default: () => [""],
    },
    autoMode: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectMode: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "label",
    },
    singleSelectMode: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: Number,
      default: 0,
    },
    apiModule: {
      type: [Function, Boolean],
      default: false,
    },
  },
  mixins: [initTextarea],
  data() {
    return {
      multyRows: this.getPreparedModelValue(),
      selectOptions: [],
      apiLoading: false,
      keyPressPreventDuplicates: window["apiTimeout"](50, false, true),
    };
  },
  created() {
    this.adapt();
    this.syncSelect();
    this.multyRows = this.getPreparedModelValue();
  },
  computed: {
    initial() {
      return this.singleSelectMode ? [this.value || ""] : this.value;
    },
    lastIndex() {
      return this.multyRows.length - 1;
    },
    canDeleteLast() {
      return this.multyRows.at(-1) /* ?.trim() */;
    },
    preparedSelectOptions() {
      return this.selectOptions.filter(
        (e) => !this.multyRows.find((i) => e == i)
      );
    },
  },
  watch: {
    multyRows() {
      this.sync();
    },
  },
  methods: {
    getPreparedModelValue() {
      let x = this.initial;
      return x && x.length ? x : [""];
      x = Array.isArray(x)
        ? x.map((e) => (this.isString(e) ? e : false)).filter((e) => e)
        : [""];
    },
    sync() {
      this.adapt();
      this.update();
    },
    delIndex(index, focusLast) {
      if (!(index > -1)) return;

      this.keyPressPreventDuplicates(() => {
        const x = this.multyRows;
        x.splice(
          ...(x.length === 1
            ? [0, 1, ""]
            : focusLast && x[index]
            ? [index, 1, ""]
            : [index, 1])
        );
        this.sync();

        if (focusLast) {
          this.focusLastEl(index);
        }
      });
    },
    focusLastEl(index) {
      window.requestAnimationFrame(() =>
        this.$refs.textareas
          ?.at(Math.min(index ?? Infinity, this.$refs.textareas?.length - 1))
          ?.focus()
      );
    },
    blurInput(ev, index) {
      if (!ev || !Number.isInteger(index)) return;

      if (!ev?.target?.value && this.lastIndex != index) {
        this.delIndex(index);
      }
    },
    initInputs() {
      window.requestAnimationFrame(() => {
        this.$refs.textareas?.forEach((el) => {
          if (!el._initialized) {
            this.initTextarea(el, this.minHeight);
          } else {
            this.resizeTextarea(el, this.minHeight);
          }
        });
      });
    },
    adapt(doNotSync) {
      const val = this.multyRows;

      if (this.autoMode) {
        if (
          val.length > 1 /* && !val.at(-1)?.trim() && !val.at(-2)?.trim() */
        ) {
          val.splice(-1);

          if (!doNotSync) this.update();
          return;
        }
        if (!val.length /*  || val.at(-1)?.trim() */) {
          this.addInput();
        }
      }
      this.initInputs();
    },
    addInput(focusLast) {
      const x = this.multyRows;
      const y = () => (focusLast ? this.focusLastEl() : "");
      const last = x.at(-1);

      if (this.singleSelectMode /* || !last?.trim() */) {
        y();
        return;
      }
      x.push("");

      this.sync();

      y();
    },
    update(val) {
      if (!val) val = this.multyRows;
      this.$emit("input", this.singleSelectMode ? val[0] || "" : val);
    },
    selectOption(option) {
      /*      
      if (!this.isObject(option)) return;
      const getValue = option[this.label];
      if (!this.isString(getValue)) return; */

      this.multyRows.splice(-1, 1, option);
    },
    syncSelect() {
      if (this.disabled || typeof this.apiModule != "function") return;

      const promise = this.apiModule(1, 2000);
      if (!this.isPromise(promise)) return;

      const error = () => {
        this.apiLoading = false;
      };

      const getLabel = (el) => {
        let obj = el;

        this.label.split(".").forEach((e) => {
          obj = obj[e];
        });

        return obj;
      };

      this.apiLoading = true;

      promise
        .then((res) => {
          if (Array.isArray(res?.data?.result)) {
            this.apiLoading = false;
            this.selectOptions = res.data.result.map((el) => {
              return { ...el, label: getLabel(el) };
            });
          } else {
            error();
          }
        })
        .catch(error);
    },
  },
};
</script>

<style>
.close-form {
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 3rem;
  height: 100%;
  margin-left: 1rem;
  cursor: pointer;
}
.form-line {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.form-line > *:not(span) {
  flex-grow: 1;
}
</style>
