export default {
  data() {
    return {
      initObserver: function(element, event, handler) {
        if (!element) return
        element[window.attachEvent ? 'attachEvent' : 'addEventListener'](
          window.attachEvent ? `on${event}` : event,
          handler,
          window.attachEvent ? undefined : false
        )
      },
    }
  },
  methods: {
    resizeTextarea(el, minHeight) {
      if (!el) return
      
      el.style.height = 'auto'
      el.style.height = `${Math.max(minHeight || 0, el.scrollHeight + 4)}px`
    },
    initTextarea(el, minHeight) {
      if (!el) return

      el._initialized = true
      const x = this.resizeTextarea.bind(null, el, minHeight)

      function delayedResize() {
        window.setTimeout(x, 0)
      }
      this.initObserver(el, 'change', x)
      for (let event of ['cut', 'paste', 'drop', 'keydown'])
        this.initObserver(el, event, delayedResize)

      x()
    },
  },
}
